@tailwind base;
@tailwind components;
@tailwind utilities;

/* 0F1C3E */
@font-face {
  /* font-family: Pier Sans; */
  /* src: url("/fonts/PierSansMedium.otf") format("opentype"); */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

p {
  margin-bottom: 10px;
  font-size: 14px;
  font-size: small;
}

li {
  font-size: small;
}

h1,h2,h3,h4,h5,h6 {
  margin-bottom: 10px;
  margin-top: 10px;
  text-decoration: underline;
}



html,
body {
  overflow-x: hidden;
  color: white;
  /* text-shadow: 0 0 10px #000; */
  font-family: Pier Sans;
  letter-spacing: 0.1em;
}

button {
  border-radius: 50px;
  border: 1px solid rgba(255,255,255,0.3);
  background: linear-gradient(90deg, #ff4c02, #ff4c02);
  transition: all 0.3s;
}

button:hover{
  background: linear-gradient(90deg, #ff4c02, #ff4c02);
}

.glow-bg{
  background-color: white;
}

.gradient{
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(239,251,250,1) 86%, rgba(194,231,230,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}